/* Custom CSS for Slider Thumb */
/* Custom CSS to override the range slider fill */
/* Override the --range-shdw variable */
input[type='range'].range {
    --range-shdw: none;
    
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none; /* Override default look */
    width: 100%; /* Required */
    height: 8px; /* Adjust the track height */
    background: linear-gradient(to right,#46278F 0%, #EF5FA7 100%); /* Your gradient */
    border-radius: 0px; /* Optional: to round the corners of the track */
  }
  
  /* For Mozilla Firefox */
  input[type='range']::-moz-range-track {
    width: 100%;
    height: 8px;
    background: linear-gradient(to right,#46278F 0%, #EF5FA7 100%);
    border-radius: 0px;
  }
  
  /* For Microsoft Edge */
  input[type='range']::-ms-track {
    width: 100%;
    height: 8px;
    background: linear-gradient(to right,#46278F 0%, #EF5FA7 100%);
    border-radius: 0px;
    border: transparent; /* Removes the border styling */
    color: transparent; /* Required to properly remove the border in IE */
  }
  
  /* For the thumb */
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: 0px; /* Adjust accordingly if thumb is not centered with the track */
    height: 50px; /* Adjust the thumb height */
    width: 50px; /* Adjust the thumb width */
    background: #ffffff;
    border-radius: 0; /* Makes it circular */
    cursor: pointer;
  }
  
  input[type='range']::-moz-range-thumb {
    height: 50px;
    width: 50px;
    background: #ffffff;
    border-radius: 0;
    cursor: pointer;
  }
  
  input[type='range']::-ms-thumb {
    height: 50px;
    width: 50px;
    background: #ffffff;
    border-radius: 0  ;
    cursor: pointer;
  }
  
  /* For Microsoft Edge and IE to remove the default style for the lower part of the track */
  input[type=range]::-ms-fill-lower {
    background: transparent;
  }
  
  /* For Microsoft Edge and IE to style the upper part of the track to match the overall track */
  input[type=range]::-ms-fill-upper {
    background: transparent;
  }
  




  /* CSS for the disabled slider - track */
input[type='range'].range:disabled {

  cursor: not-allowed; /* Change cursor to indicate it's not interactable */
}

/* CSS for the disabled slider thumb */
input[type='range'].range:disabled::-webkit-slider-thumb {
  background: #999; /* Darker gray for the disabled thumb */
}

input[type='range'].range:disabled::-moz-range-thumb {
  background: #999; /* Darker gray for the disabled thumb */
}

input[type='range'].range:disabled::-ms-thumb {
  background: #999; /* Darker gray for the disabled thumb */
}