@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.ReactP5Wrapper {
  position: fixed; /* Make it cover the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Placed behind other content */
}

.content-container {
  position: relative; /* This allows z-index to be applied */
  z-index: 10; /* Higher than the P5 sketch */
}

.App {

  position: relative;
  overflow-y: hidden;
}

.characterContainer {
  width: 80%;
}

.card {
  border-radius: 0;
} 

.card-body {
  border-radius: 0;
} 

.black-background {
  background-color: rgb(0, 0, 0);
} 

.explanation_area {
  height: 180px;
  overflow: auto;
}