/* src/components/Footer/footer.css */
.footer {
  pointer-events: none;
  background-color: #00000000;
  padding: 20px;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  position: fixed;
  bottom: 0;
}
