.logos {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;    /* Center items horizontally in the cross axis */
  justify-content: center; /* Center items vertically in the main axis */
  padding-top: 20px;
}
  /* .fractal-canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0; 
    pointer-events: none; 
  } */

.logo-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
