body {
  margin: 0;
  font-family: 'Space Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@keyframes fadeMoveUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeMoveUp {
  animation: fadeMoveUp 0.5s ease-out;
}

.animate-delay-1 {
  animation-delay: 0.2s;
}

.animate-delay-2 {
  animation-delay: 0.4s;
}

.animate-delay-3 {
  animation-delay: 0.6s;
}

.animate-delay-4 {
  animation-delay: 0.8s;
}

.animate-delay-5 {
  animation-delay: 1s;
}

.animate-delay-6 {
  animation-delay: 1.2s;
}

.animate-delay-7 {
  animation-delay: 1.4s;
}

.animate-delay-8 {
  animation-delay: 1.6s;
}

.animate-delay-9 {
  animation-delay: 1.8s;
}

.animate-delay-10 {
  animation-delay: 2.0s;
}




.animate-fill-forwards {
  animation-fill-mode: forwards;
}


@keyframes drawBorder {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  25% {
    width: 100%;
    height: 0;
    border-top-color: oklch(0.4912 0.3096 275.75 / 1);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  50% {
    width: 100%;
    height: 100%;
    border-top-color: oklch(0.4912 0.3096 275.75 / 1);
    border-right-color: oklch(0.4912 0.3096 275.75 / 1);
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  75% {
    width: 100%;
    height: 100%;
    border-top-color: oklch(0.4912 0.3096 275.75 / 1);
    border-right-color: oklch(0.4912 0.3096 275.75 / 1);
    border-bottom-color: oklch(0.4912 0.3096 275.75 / 1);
    border-left-color: transparent;
  }
  100% {
    width: 100%;
    height: 100%;
    border-top-color: oklch(0.4912 0.3096 275.75 / 1);
    border-right-color: oklch(0.4912 0.3096 275.75 / 1);
    border-bottom-color: oklch(0.4912 0.3096 275.75 / 1);
    border-left-color: oklch(0.4912 0.3096 275.75 / 1);
  }
}

.animate-drawBorder {
  position: relative;
}

.animate-drawBorder:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 1px solid transparent;
  animation: drawBorder 1s linear forwards;
}




.blink {
  animation: blink-animation 2s linear infinite;
}

@keyframes blink-animation {
  50% {
    
    opacity: 0.05;
  }
}


.full-size-line {
  width: 100%;
  height: 4px;
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 10px;
}

.highlight {
  background-color: #fff;
  box-shadow: 10px 0 0 #fff, -10px 0 0 #fff;
  display: inline;
  padding-top: 10px;
  margin-left: 10px;
  margin-top: 20px;
}

.video-container {
  position: relative;
  margin-top: 20px;
  padding: 10px;
}

.video-text-left {
  position: absolute;
  z-index: 1;
}

.video-text-right {
  position: absolute;
  z-index: 1;
  right: 0;
}